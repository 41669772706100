import React from "react";
import Layout from "../../components/Layout";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { graphql, PageProps } from "gatsby";
import "./styles.scss";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import { LocalisedPageContext } from "../../types/PageTypes";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";
import { ImageInterface, LocalizedContextInterface, Seo, SanityColorList } from "../../types/SanityTypes";
import HeroBanner from "../../components/HeroBanner";
import BlockRenderer from "../../components/BlockRenderer";

type AboutUsQueryProps = {
  sanityAboutUs: {
    _id: string;
    banner: {
      heading: string;
      subHeading?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
    };
    seo: Seo;
    slug: {
      current: string;
    };
    _rawLandingSections: any;
  };
} & LocalizedContextInterface;

type AboutUsPageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String) {
    sanityAboutUs(_id: { eq: $_id }) {
      _id
      slug {
        current
      }
      seo {
        metaDescription
        metaTitle
      }
      banner {
        heading
        subHeading
        primaryColor {
          value
          title
        }
        contentAlignment
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        _rawHeroVideo(resolveReferences: { maxDepth: 10 })
      }
      _rawLandingSections(resolveReferences: { maxDepth: 10 })
    }
    ...LocalizedContext
  }
`;

const AboutUs = (props: PageProps<AboutUsQueryProps, AboutUsPageContext>) => {
  const language = props.pageContext.language;
  const aboutUs = props.data.sanityAboutUs;
  const { htmlLang, siteUrl } = useSiteMetadata(language);

  const pageTitle = aboutUs.banner.heading;
  const localizedPath = withPrefix(getLocalizedPath(aboutUs.slug?.current, aboutUs._id));

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"about"}
      analyticsTagMeta={{ promoId: "11" }}
    >
      <StructuredData
        type={"AboutPage"}
        data={aboutUs.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${aboutUs.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={aboutUs.seo.metaTitle}
        description={aboutUs.seo.metaDescription}
        imageUrl={aboutUs.banner._rawHeroImage.asset.url}
        imageAlt={aboutUs.banner._rawHeroImage.alt}
      />
      <div className="page_about-us">
        <HeroBanner
          image={aboutUs.banner._rawHeroImage}
          title={aboutUs.banner.heading}
          alignment={aboutUs.banner.contentAlignment}
          primaryColor={aboutUs.banner.primaryColor}
          subtitle={aboutUs.banner.subHeading}
          video={aboutUs.banner._rawHeroVideo}
        />
        <section data-testid="about-us">
          {aboutUs._rawLandingSections &&
            aboutUs._rawLandingSections.map((section, index) => (
              <BlockRenderer key={"landingSection" + index} section={section} />
            ))}
        </section>
      </div>
    </Layout>
  );
};

export default AboutUs;
